<template>
  <!-- MODAL MAILS FRAIS -->
  <b-modal
    v-model="isOpen"
    @hidden="$emit('custumChange')"
    ref="vusialisationModal"
    :title="'Email à envoyer Pour ' + computedName"
    size="lg"
    no-close-on-backdrop
    :hide-footer="true"
    modal-class="send-mail-component-cdg-modal"
  >
    <template #modal-header>
      <div>
        <h5>
          Email à envoyer par <b>{{ computedName }}</b>
        </h5>
      </div>
      <div class="actionModelMailVendeur d-flex justify-content-center mb-1">
        <b-button
          class="button-valide-style mr-4"
          type="submit"
          @click="previous()"
          v-if="index > 0 && index < dataToUse.length"
        >
          <span>
            Précédent
          </span>
        </b-button>
        <span
          v-for="(i, indexVendeur) in dataToUse"
          :key="'indexVendeur' + indexVendeur"
          class="circleStep"
          :class="{
            circleStepActive: indexVendeur == index,
            circleStepSend: dataToUse[indexVendeur].mailSend == true
          }"
        ></span>
        <b-button
          class="button-valide-style ml-4"
          type="submit"
          v-if="index < dataToUse.length - 1 && index >= 0"
          @click="next()"
        >
          <span>
            Suivant
          </span>
        </b-button>
        <font-awesome-icon
          icon="times"
          @click="$emit('custumChange')"
          class="mt-2 ml-4"
        />
      </div>
    </template>
    <div
      v-if="getLoaderSendMailCdg == true"
      class="loading-custom-template-block "
      style="top:-46px"
    >
      <Half-circle-spinner
        :animation-duration="1000"
        :size="50"
        :color="'#4d4bac'"
        class="loeder"
      />
    </div>

    <div
      v-if="getLoadingMailDataCdg == true"
      class="loading-custom-template-block"
    >
      <Half-circle-spinner
        :animation-duration="1000"
        :size="50"
        :color="'#4d4bac'"
        class="loeder"
      />
    </div>
    <div class="mail-vendeur-box">
      <div class="mail-vendeur-content">
        <template-mail
          @eventMailSend="
            dataToUse[index].updateMailSend(true);
            $emit('eventMailSendRefresh');
          "
          :changeVendeurIndex="changeVendeurIndex"
          :dataToUse="dataToUse[index]"
          v-if="dataToUse && dataToUse[index]"
        />
      </div>
    </div>
  </b-modal>
  <!-- END MODAL MAILS FRAIS -->
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    dataToUse: { required: true },
    isOpen: { required: true }
  },
  data() {
    return {
      index: 0,
      changeVendeurIndex: true
    };
  },
  computed: {
    ...mapGetters(['getLoadingMailDataCdg', 'getLoaderSendMailCdg']),
    computedDisabledSend() {
      return true;
    },
    computedName() {
      return this.dataToUse &&
        this.dataToUse[this.index] &&
        this.dataToUse[this.index].name_vendeur
        ? this.dataToUse[this.index].name_vendeur
        : '';
    }
  },
  methods: {
    previous() {
      this.changeVendeurIndex = !this.changeVendeurIndex;
      this.index = this.index - 1;
    },
    next() {
      this.changeVendeurIndex = !this.changeVendeurIndex;
      this.index = this.index + 1;
    }
  },
  watch: {
    isOpen: function() {
      if (this.isOpen == true) {
        this.$refs['vusialisationModal'].show();
      } else {
        this.$refs['vusialisationModal'].hide();
      }
    }
  },
  components: {
    templateMail: () => import('./templateMail.vue')
  }
};
</script>

<style scoped lang="scss">
.mail-vendeur-box {
  height: calc(100vh - 78px);
  .mail-vendeur-content {
    height: calc(100vh - 126px);
  }
}
.actionModelMailVendeur {
  .button-valide-style {
    &:active {
      height: auto;
    }
  }
}
</style>
<style lang="scss">
.send-mail-component-cdg-modal {
  .modal-header,
  .modal-body {
    padding: 0.5rem;
  }
}
</style>
